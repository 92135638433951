<template>
  <div class="valorador" v-if="ready">
    <div>
      <h2 class="left">Usuarios</h2>
      <div class="box left" style="padding:20px">
        <h3>Crear usuario</h3>
        <vs-row vs-type="flex" vs-align="flex-end" vs-justify="space-between" vs-w="12">
          <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="6">
            <vs-input type="text" v-model="newClient.name" class="inputx" placeholder="Nombre" />
              &nbsp;&nbsp;&nbsp;

            <vs-input
              type="text"
              v-model="newClient.email"
              class="inputx"
              placeholder="Email"
              icon=".."
              icon-pack="fab fa-google"
            />
              &nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;

            <vs-button @click="create(newClient)">Crear</vs-button>
          </vs-col>
        </vs-row>
      </div>
    </div>
    <br />
    <br />
    <table-render
      :rows.sync="rows"
      :schema="schema"
      :actions="actions"
      :replacements="replacements"
      v-if="ready"
      :link="open"
      :noAutoResize="true"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapMutations } from 'vuex'
import { onSnapshot, collection, db, setDoc, addDoc, doc, orderBy, query, updateDoc, getDoc, getDocs, deleteDoc } from '../firebase'
import Caja from './Caja.vue'
import TableRender from './Rage/TableRender.vue'
export default {
  components: { Caja, TableRender },
  name: 'Usuarios',
  data() {
    return {
      ready: false,
      clients: [],
      actions: [],
      replacements: {},
      tenants: [],
      params: {},
      ready: false,
      rows: [],
      schema: [],
      newClient: {
        active: true,
        currentTenant: '',
        tenants: [],
      },
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  async mounted() {

    this.schema = [
      { label: 'Nombre', visible: true, key: 'name', width: 300 },
      { label: 'Email', visible: true, key: 'email', width: 300 },
      { label: 'Empresa', visible: true, key: 'currentTenant', width: 300 },
      { label: 'Ops', visible: true, key: 'actions', width: 200 },
      // {label: 'watch', visible: true, key: 'watch', width: 100},
    ];
    
    const paramsRef = doc(collection(db, 'config'), 'params');
    onSnapshot(paramsRef, async (params) => {
      //do all stuff after params are fetched, because the table needs to know what buttons to show
      if (params.data()) this.params = params.data();
      this.ready = false;
      this.tenantsRef = (await getDocs(collection(db, 'clients'))).docs;
      this.tenants = this.tenantsRef.map((tenant) => {
        return { id: tenant.id, ...tenant.data() };
      });

      this.actions = [
        { action: id => this.switchActive({ id }), icon: 'power-off', switchKey: 'active' },
        {...this.params.removeAccess? { action: id => this.del({ id }), icon: 'trash', color: 'darkred' } : null},
      ];

      this.replacements = {
        currentTenant: (tenant) => {
          console.log(`veo`, this.tenants);
          return this.tenants.find(t=>t.id===tenant).name
        },
      };


      //traer usuarios de firebase
      const q = query(collection(db, "access"), orderBy('created', 'desc'));
      onSnapshot(q, snapshot => {
        const clients = []
        this.ready = false;
        this.rows = [];
        snapshot.forEach(doc => {
          clients.push({ id: doc.id, ...doc.data() })
        });
        this.$nextTick(() => {
          const rows = []
          for (const client of clients) {
            rows.push({ ...client })
          }
          this.rows = rows;
        });
        this.ready = true;
      });

    });

  },
  methods: {
    switchActive(clientId) {
      const client = this.rows.find(client => client.id === clientId.id);
      client.active = !client.active;
      delete client.actions;
      updateDoc(doc(db, "access", client.id), client);
    },
    open(client) {
      console.log(`abro`, client);
      this.$router.push('/usuarios/' + client.id);
    },
    go(uri) {
      location = '/' + uri
    },
    del(client) {
      console.log(`borreo`, client);
      if (!confirm('¿borrar?')) return
      console.log(`reborro`,);
      // this.clients.splice(this.clients.indexOf(client), 1)
      deleteDoc(doc(db, "access", client.id))
    },
    create(client) {
      addDoc(collection(db, "access"), { ...client, created: new Date() })
      this.newClient.name = '';
      this.newClient.email = '';
    }
  },
  watch: {
    rating: function (val) {
      if (val < 4) {
        this.showFeedback = true
      } else {
        location = 'https://search.google.com/local/writereview?placeid=ChIJ9VH-EofFYpYR0f7LytCPG7U'
      }
      this.$emit('rating', val)
    }
  }
}
</script>
